header {
    height: 135vh;
    overflow: hidden;
    
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* =========== CTA ================ */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* =========== HEADER SOCIALS ================ */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 11rem;
    bottom: 3rem;
    top:45vh;
}

.header__text{
    background: linear-gradient(var(--color-primary), transparent);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 15rem;
    bottom: 3rem;
    right:15rem;
    top:38vh;
    height:10rem;
    font-size:xx-large;
}
.header__logo {
    display: flex;
    width:12rem;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 5rem;
    bottom: 3rem;
    top:1vh;
}


.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ================== ME ================= */
/* .me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
} */

.me {
    width: 55rem;
    height: 60rem;
    position: absolute;
    left: calc(25% - 11rem);
    margin-top: 4rem;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}

video {
    position: absolute;
    width: auto;
    height: 100%;
    top: 0;
    left: 0;
  }

/* ================== SCROLL DOWN ================= */

/* .scroll__down {
    position: absolute;
    right: 25rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 1rem;
    top:20vh;
} */

/* ======== MEDIA QUERIES (MEDIUM DEVICES) ========= */
@media screen and (max-width: 1024px) { 
    header {
        height: 130vh;
    }
   
}

/* ======== MEDIA QUERIES (SMALL DEVICES) ========= */
@media screen and (max-width: 600px) { 
    header {
        height: 35vh;
    }
    .header__logo {
        width:8rem;
        left:1rem;
    }
    .header__socials,
    .scroll__down {
        display: none;
    }
    .header__text{
        left: 5rem;
        bottom: 3rem;
        right: 5rem;
        height: 5rem;;
        top: 15vh;
        font-size: small;
    }
    
}